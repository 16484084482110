"use client";
import {PropsWithChildren} from "react";
import Link from "next/link";
import {usePathname} from "next/navigation";
import Routes from "@/navigation/Routes";

import styles from "./NavigationMain.module.css";

type Props = {
    position?: "top" | "bottom"
    className?: string
}

export default function NavigationMain(
    {
        className = "",
        position = "top",
    }: Props
) {
    const path = usePathname();
    return (
        <nav className={[styles.mainNav, styles[position], className].join(" ")}>
            <Tab active={path.startsWith(Routes.references.base)}>
                <Link href={Routes.references.websites}>
                    Referenzen
                </Link>
            </Tab>

            <Tab active={path.startsWith(Routes.service)}>
                <Link href={Routes.service}>
                    Dienste
                </Link>
            </Tab>

            <Tab active={path.startsWith(Routes.starterkits)}>
                <Link href={Routes.starterkits}>
                    Starterkits
                </Link>
            </Tab>
        </nav>
    )
}

type TabProps = {
    active?: boolean
}

export const Tab = (
    {
        active = false,
        children
    }: PropsWithChildren<TabProps>
) => {
    const classes = [styles.tab]
    if (active) {
        classes.push(styles.isActive)
    }
    return <div className={classes.join(" ")}>
        {children}
        {active ? <span className={styles.tabLine}></span> : null}
    </div>
}