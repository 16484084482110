"use client";

import Link from "next/link";
import {usePathname} from "next/navigation";
import Routes from "@/navigation/Routes";
import Logo from "@/components/Logo/Logo";
import Button from "@/components/Button/Button";

import styles from './SiteHeader.module.css';
import NavigationMain, {Tab} from "@/components/NavigationMain/NavigationMain";

type Props = {
    className?: string
}

export default function SiteHeader(
    {
        className = ""
    }: Props
) {
    const path = usePathname();
    return (
        <header className={styles.header + " " + className}>

            <div>
                <Tab active={path == "/"}>
                    <Link href="/" className={styles.logo}>
                        <Logo className={styles.svg}/>
                        Edward Bock
                    </Link>
                </Tab>
            </div>

            <NavigationMain className={styles.mainNav} />

            <div>
                <Button className={styles.cta} href={Routes.request}>Anfragen</Button>
            </div>
        </header>
    )
}
