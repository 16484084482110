import {PropsWithChildren, ReactEventHandler} from "react";
import Link from "next/link";
import styles from './Button.module.css';

type BaseButtonProps = {
    className?: string
    enabled?: boolean
    destructive?: boolean
    size?: "small" | "default" | "large"
}

type SubmitButtonProps = {
    type: "submit"
} & BaseButtonProps

const isSubmitButton = (props: unknown): props is SubmitButtonProps => {
    return props != null && typeof props == "object" && "type" in props && props.type == "submit";
}

type ButtonProps = {
    onClick: ReactEventHandler<HTMLButtonElement>
} & BaseButtonProps

const isButtonProps = (props: unknown): props is ButtonProps => {
    return props != null && typeof props == "object" && "onClick" in props && typeof props.onClick == "function";
}

type LinkButtonProps = {
    href: string
} & BaseButtonProps

const isLinkButton = (props: unknown): props is LinkButtonProps => {
    return props != null && typeof props == "object" && "href" in props && typeof props.href == "string";
}

type Props = SubmitButtonProps | ButtonProps | LinkButtonProps

const Button = (
    {
        children,
        className,
        enabled = true,
        destructive = false,
        size = "default",
        ...rest
    }: PropsWithChildren<Props>
) => {

    const classNames = [styles.button, styles[size], className ?? ""];
    if(destructive){
        classNames.push(styles.destructive)
    }

    if (isLinkButton(rest)) {
        return (
            <Link
                href={rest.href}
                aria-disabled={!enabled}
                className={classNames.join(" ")}
            >
                {children}
            </Link>
        )
    }

    return (
        <button
            onClick={isButtonProps(rest) ? rest.onClick : undefined}
            type={isSubmitButton(rest) ? rest.type : undefined}
            className={classNames.join(" ")}
            disabled={!enabled}
        >
            {children}
        </button>
    )

}

export default Button;
