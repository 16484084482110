
const referencesBase = "referenzen";

type ReferenceType = "websites" | "apps" | "plugins"
type Starterkit = "wordpress-nextjs" | "wordpress-theme" | "wordpress-plugin"

const Routes = {
    home: "/",
    references: {
        base: `/${referencesBase}`,
        websites: `/${referencesBase}/websites`,
        apps: `/${referencesBase}/apps`,
        plugins: `/${referencesBase}/plugins`,
    },
    reference: (type: ReferenceType, project: string)=> `${Routes.references.base}/${type}/${project}`,
    career: `/werdegang`,
    develop: "/entwickeln",
    service: "/dienste",
    workshops: "/workshops",
    workshop: (module: string) => `${Routes.workshops}/${module}`,
    imprint: "/impressum",
    privacy: "/datenschutz",
    request: "/anfragen",
    starterkits: '/starterkits',
    starterkit: (kit: Starterkit, lang: "" | "en" = "") => `${Routes.starterkits}/${kit}${lang != "" ? `/${lang}` : ""}`,
    social: {
        github: "https://github.com/EdwardBock",
        wordpress: "https://profiles.wordpress.org/edwardbock/",
        writeBetterWordPressCode: "https://medium.com/write-better-wordpress-code",
        digitalePracht: "https://digitale-pracht.de/blog/2016/07/19/viel-bewegen-interview-mit-edward-bock/",
    },
    snake: "/snake",
    blog: "/blog",
    blogPost: (slug: string)=> {
        return `${Routes.blog}/${slug}`;
    }
} as const;

export default Routes;
