import styles from './Logo.module.css';

type Props = {
    className?: string
}
const Logo = (
    {
        className = ""
    }: Props
) => {
    return (
        <svg
            className={`${styles.svg} ${className} `}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 110 50"
        >
            <g fill="currentColor">
                <polygon points="18.5 49.5 37.01 49.5 19 25.64 37.21 0 18.5 0 .5 25.5 18.5 49.5"/>
                <polygon points="82.21 0 63.7 0 81.71 23.86 63.5 49.5 82.21 49.5 100.21 24 82.21 0"/>
                <polygon points="38.5 49.5 55.5 49.5 62.5 0 45.5 0 38.5 49.5"/>
            </g>
        </svg>
    )
}

export default Logo
